import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/blogPost.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "and-so-it-begins",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#and-so-it-begins",
        "aria-label": "and so it begins permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`And so it begins…`}</h1>
    <p><a parentName="p" {...{
        "href": "https://pegasys.fi/"
      }}>{`Pegasys`}</a>{` is a permissionless decentralized exchange (DEX) running on Syscoin NEVM, Syscoin’s EVM-compatible protocol extension, that aims to bring something fresh
and impactful to the DeFi space.
It uses the same automated market-making (AMM) model as `}<a parentName="p" {...{
        "href": "https://docs.uniswap.org/protocol/V2/concepts/protocol-overview/how-uniswap-works"
      }}>{`Uniswap`}</a>{`,
features a native governance token called PSYS that is community distributed and is capable of trading all tokens issued on `}<a parentName="p" {...{
        "href": "https://syscoin.org/"
      }}>{`Syscoin`}</a>{`.
In a crowded marketplace with multiple contenders, Pegasys offers three critically important benefits: `}<strong parentName="p">{`fast and cheap trades`}</strong>{`,
`}<strong parentName="p">{`high-quality development`}</strong>{`, and a realistic, fair, and `}<strong parentName="p">{`market-compatible token distribution`}</strong>{`.`}</p>
    <p>{`Since Pegasys is built on Syscoin, it enables users to swap assets at scale, with bitcoin-merge-mined security, processing power, and
transaction fees as low as a few cents.
Our DEX also leverages the best of both worlds: `}<strong parentName="p">{`community governance and battle-hardened software expertise from Pegasys Labs`}</strong>{`.
The native governance token, PSYS, enables the community to drive the development of the product in full by taking part in the
various liquidity mining programs available, as well as special events and partnerships we have planned down the line.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "600px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/0891fbcb362b64e5812f7ee626f292a6/b4294/sys.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABQBAQAAAAAAAAAAAAAAAAAAAAL/2gAMAwEAAhADEAAAAbUTJK2jOEP/xAAZEAADAQEBAAAAAAAAAAAAAAAAAQIDEhH/2gAIAQEAAQUCrxD0SOEytKHqzuj/xAAYEQACAwAAAAAAAAAAAAAAAAABAhARIf/aAAgBAwEBPwFAL2P/xAAWEQADAAAAAAAAAAAAAAAAAAACEBH/2gAIAQIBAT8BKr//xAAYEAADAQEAAAAAAAAAAAAAAAAAATEhEP/aAAgBAQAGPwLBZykRT//EABoQAQACAwEAAAAAAAAAAAAAAAEAIRARMVH/2gAIAQEAAT8hqhFYxRfkACNMBrqETA//2gAMAwEAAgADAAAAEOjv/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARUf/aAAgBAwEBPxBixIcv/8QAFhEBAQEAAAAAAAAAAAAAAAAAAREQ/9oACAECAQE/EFBHP//EABwQAQACAwEBAQAAAAAAAAAAAAEAIRExQVFx0f/aAAgBAQABPxDN6qOu/jLwjHaDVAEEu41lYdOeS6BDGcWfJrvrwn//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Scalability + BTC security",
              "title": "Scalability + BTC security",
              "src": "/static/0891fbcb362b64e5812f7ee626f292a6/b4294/sys.jpg",
              "srcSet": ["/static/0891fbcb362b64e5812f7ee626f292a6/f93b5/sys.jpg 300w", "/static/0891fbcb362b64e5812f7ee626f292a6/b4294/sys.jpg 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Scalability + BTC security`}</figcaption>{`
  `}</figure></p>
    <p>{`Users of existing AMMs, such as Uniswap, Pancakeswap, Sushiswap, Pangolin, and Quickswap are already familiar with their mechanism of action.
Therefore, the rest of this post does not discuss how Pegasys achieves its trading capabilities.
Instead, we discuss the mentality behind the project, with further technical optimizations, tokenomics, and overall peculiarities being discussed in future blog posts.`}</p>
    <p>{`Thanks to the power of `}<a parentName="p" {...{
        "href": "https://github.com/pegasys-fi"
      }}>{`open-source software`}</a>{`, we’re very much taking other protocols’ failures and successes into consideration when
building our own AMM.`}</p>
    <p>{`On the other hand, we don’t plan on taking a `}<em parentName="p">{`“basic-Uniswap-fork”`}</em>{` approach with Pegasys like Quickswap or Pangolin did.
Our vision for the protocol is much more similar to what Sushi did with the AMM model by putting our own twist on top of
Uniswap’s solid algorithms in order to to make it optimal for Syscoin and for our own community. Currently, we have Syscoin-specific(L1) and `}<a parentName="p" {...{
        "href": "https://rollux.com/"
      }}>{`Rollux`}</a>{`-specific(L2)
features and further scalability-focused features planned for the near future.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "378px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/75242dd02eedf055a3d55fc21ff60638/a5608/pegasus-painting.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "124.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAZABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEBQH/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAcPrddcQtEmdPW2ocH//xAAcEAACAgMBAQAAAAAAAAAAAAABAgMSABExIUH/2gAIAQEAAQUCwqQaHF8VK1n2ZA5qLGMiTa9+Hv8A/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEREP/aAAgBAwEBPwGYiH//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAdEAADAAEFAQAAAAAAAAAAAAAAAREhAgMgMVFh/9oACAEBAAY/AiHQ29NXpt/GYWCUnH//xAAeEAEAAgIBBQAAAAAAAAAAAAABABEhMWEQQVFxgf/aAAgBAQABPyHviXAI8x8iG81fiZ9aaPUDb0YKIUlEraO9ywRS+ekazdP/2gAMAwEAAgADAAAAEEMb8//EABYRAQEBAAAAAAAAAAAAAAAAAAARAf/aAAgBAwEBPxDKRi4l/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAf/8QAHhABAAMAAgIDAAAAAAAAAAAAAQARITGhQXFRYYH/2gAIAQEAAT8QqwayAVosBVw1AmcjY+oAZQy0tyEsVb86MezJUDdmKF/H3cMMa0Plrxx4gICr/FNt+3qCzQXlbQX1OKdmdqf/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "An AMM truly built for Syscoin, by the community for the community",
              "title": "An AMM truly built for Syscoin, by the community for the community",
              "src": "/static/75242dd02eedf055a3d55fc21ff60638/a5608/pegasus-painting.jpg",
              "srcSet": ["/static/75242dd02eedf055a3d55fc21ff60638/f93b5/pegasus-painting.jpg 300w", "/static/75242dd02eedf055a3d55fc21ff60638/a5608/pegasus-painting.jpg 378w"],
              "sizes": "(max-width: 378px) 100vw, 378px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`An AMM truly built for Syscoin, by the community for the community`}</figcaption>{`
  `}</figure></p>
    <p>{`Just like current leading DEXs, such as Uniswap, anyone can trade any ERC20 token on Pegasys.
If an asset isn’t currently supported on the platform, users can list any ERC20 token on Pegasys by providing
liquidity to enable instant asset swaps. They will then earn fees every time other users trade via that trading pair.`}</p>
    <p>{`Liquidity providers will be incentivized by both yield farming and liquidity mining opportunities. Users who provide liquidity
for incentivized trading pairs on Pegasys will not only be entitled to a percentage of the platform’s 0.3% trading fees,
but will also earn Pegasys’ native PSYS governance token for their participation.`}</p>
    <p>{`The protocol will be governed by PSYS token holders, who will be able to create proposals for and vote on various factors
relating to the running of the protocol. Pegasys is being built for the community, to be run (eventually) entirely by the community.
Check out Pegasys’ `}<a parentName="p" {...{
        "href": "https://gov.pegasys.fi/"
      }}>{`governance forum`}</a>{` for more information.`}</p>
    <h1 {...{
      "id": "spoiler-alert",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#spoiler-alert",
        "aria-label": "spoiler alert permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Spoiler alert`}</h1>
    <p>{`One central optimization Pegasys is doing to the protocol is using more modern ERC-20 extensions that will allow our users to
cut steps on token allowance and leverage a better UX for trading. Syscoin’s bullish efforts brought Layer 2 technologies
to the network with Rollux and, Pegasys is already up and running around there. All information regarding Pegasys v3, concentrated liquidity and, the latest upgrade to the protocol,
can be seen in the article `}<a parentName="p" {...{
        "href": "../pegasys-v3"
      }}>{`Welcome to PegasysV3: A New Era of Decentralized Finance`}</a>{`.`}</p>
    <p>{`We believe the Syscoin community is one of the most constructive and tech-oriented around so it’s more than fair to step up the AMM
game for the incoming `}<strong parentName="p">{`DeFi Renaissance`}</strong>{` in the network. Not only that, Pegasys can be a gateway from other EVMs to Syscoin NEVM so
it’s really important for us to be competitive outside Syscoin as well.`}</p>
    <p>{`Finally, PSYS will have very unique and realistic tokenomics. We appreciate the optimism involved in PGNs 28-year distribution plan
and the aggressive 4-year liquidity mining Quickswap brought forward but we definitely think a successful yield farming strategy needs
more room to grow and multiple community-driven, as well as data-driven, decisions along the way to provide a realistically fair token
distribution for a protocol that is built to last. And most of all: `}<em parentName="p">{`to evolve`}</em>{`.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/641bed5be16144b40690d7399b79ff90/d924d/horse-joust.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.333333333333336%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAECBP/EABYBAQEBAAAAAAAAAAAAAAAAAAIAAf/aAAwDAQACEAMQAAABxK6GQMl//8QAGhAAAgIDAAAAAAAAAAAAAAAAAAIBIhExQf/aAAgBAQABBQKXtLVMHW3/AP/EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/ASf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAWEAEBAQAAAAAAAAAAAAAAAAARACD/2gAIAQEABj8CJz//xAAYEAEBAQEBAAAAAAAAAAAAAAABABFBIf/aAAgBAQABPyEOsxGyN3PW4IE//9oADAMBAAIAAwAAABA8L//EABcRAQEBAQAAAAAAAAAAAAAAAAEAETH/2gAIAQMBAT8QPAjE2//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/EGf/xAAaEAEBAAMBAQAAAAAAAAAAAAABEQAhMWFR/9oACAEBAAE/ELRQiVlHzKXIpITXuJQU6Dg0AdH7gGgInMNqTWf/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "An army of fluid Pegasi is coming, make sure to grab your seat...",
              "title": "An army of fluid Pegasi is coming, make sure to grab your seat...",
              "src": "/static/641bed5be16144b40690d7399b79ff90/e5166/horse-joust.jpg",
              "srcSet": ["/static/641bed5be16144b40690d7399b79ff90/f93b5/horse-joust.jpg 300w", "/static/641bed5be16144b40690d7399b79ff90/b4294/horse-joust.jpg 600w", "/static/641bed5be16144b40690d7399b79ff90/e5166/horse-joust.jpg 1200w", "/static/641bed5be16144b40690d7399b79ff90/d924d/horse-joust.jpg 1238w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`An army of fluid Pegasi is coming, make sure to grab your seat...`}</figcaption>{`
  `}</figure></p>
    <p>{`Future blog posts will get into more detail into our vision of each one of those topics. From day one, we’re completely open to
your take on what should and shouldn’t be done, so feel free to join our `}<a parentName="p" {...{
        "href": "https://discord.gg/Pegasys"
      }}>{`Discord`}</a>{` and voice your opinions on what we’re building!`}</p>
    <h1 {...{
      "id": "conclusion-a-public-good-for-syscoin-and-evms",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#conclusion-a-public-good-for-syscoin-and-evms",
        "aria-label": "conclusion a public good for syscoin and evms permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Conclusion: A Public Good for Syscoin and EVMs`}</h1>
    <p>{`Syscoin has been at the forefront of modernizing Bitcoin-based blockchain applications and the release of NEVM only brings that to the next level. We can expect an extensive and vibrant ecosystem to develop, especially with future L2 plans and Syscoin Foundation’s known tech-focused vision.
An effective, dynamic, and evolving AMM is definitely a key piece of Syscoin’s DeFi LEGO box and Pegasys Labs is glad to bring life to the Pegasys.`}</p>
    <p>{`By incorporating a community-focused governance structure, fair and fluid token distribution model, solid development team as well as great ties
to the Syscoin Foundation, Pegasys is empowering traders whilst removing the costly barriers to entry to DEXs for the general
crypto user. Liquidity mining and yield farming opportunities will incentivize the growth of a strong liquidity provider
ecosystem and long-term token holders that will actively be part of the architecture of an early and potentially huge ecosystem
of Pegasys users.`}</p>
    <p>{`Thank you for joining our community, we are really excited about what the future holds!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      